/**
 * Adjusts the padding of mainContent or mainShapes to ensure equal heights.
 * This function should be called on window resize events to maintain layout consistency.
 */
const equalContentHeight = () => {
    // Define constants for thresholds and offsets
    const MOBILE_WIDTH_THRESHOLD = 768;
    const HEIGHT_OFFSET = 50;
    const TOTAL_SHAPES = 7;

    /**
     * Logs an error message if an element is not found.
     * @param {string} id - The ID of the element to find.
     * @returns {HTMLElement|null} The found element or null.
     */
    const getElementByIdSafe = (id) => {
        const element = document.getElementById(id);
        if (!element) {
            console.error(`Element with ID '${id}' not found.`);
        }
        return element;
    };

    /**
     * Calculates the height difference between mainShapes and mainContent, adjusted by HEIGHT_OFFSET.
     * @param {number} shapeHeight - The height of the mainShapes element.
     * @param {number} contentHeight - The height of the mainContent element.
     * @param {number} offset - The offset to adjust the height difference.
     * @returns {number} The calculated height difference.
     */
    const calculateHeightDifference = (shapeHeight, contentHeight, offset) => {
        return shapeHeight - contentHeight - offset;
    };

    /**
     * Updates the padding of mainContent or mainShapes based on the height difference.
     * @param {number} difference - The height difference to determine padding adjustments.
     */
    const updatePadding = (difference) => {
        const paddingValue = `${difference}px`;
        if (difference > 0) {
            mainContent.style.paddingBottom = paddingValue;
            mainShapes.style.paddingBottom = '0px'; // Reset padding if previously set
        } else {
            mainShapes.style.paddingBottom = paddingValue;
            mainContent.style.paddingBottom = '0px'; // Reset padding if previously set
        }
    };

    /**
     * Retrieves and validates all necessary DOM elements.
     * @returns {Object|null} An object containing cached DOM elements or null if any are missing.
     */
    const getElements = () => {
        const mainContent = getElementByIdSafe('main-content-inner');
        const mainShapes = getElementByIdSafe('main-shapes');

        if (!mainContent || !mainShapes) return null;

        // Collect all shape elements (if needed for future use)
        const shapes = Array.from({ length: TOTAL_SHAPES }, (_, index) =>
            getElementByIdSafe(`shape-${index + 1}`)
        ).filter(shape => shape !== null); // Filter out any null values

        return { mainContent, mainShapes, shapes };
    };

    // Exit early if the screen width is less than or equal to the mobile threshold
    if (window.innerWidth <= MOBILE_WIDTH_THRESHOLD) return;

    // Retrieve and validate DOM elements
    const elements = getElements();
    if (!elements) return;

    const { mainContent, mainShapes } = elements;

    // Calculate the height difference
    const heightDifference = calculateHeightDifference(
        mainShapes.offsetHeight,
        mainContent.offsetHeight,
        HEIGHT_OFFSET
    );

    // Update the padding based on the calculated height difference
    updatePadding(heightDifference);
};

export default equalContentHeight;
