const cursor = () => {
    const circleCursor = document.querySelector('.circle-cursor');
    const hoverTargets = '.more, footer a, .swiper-button-prev, .swiper-button-next, .modal-close, .tab-title, .sitemap-item, .career-more, .modal-form input, .modal-form button';
    const usImage = document.querySelector('.us');

    // Debounce function to optimize mousemove events
    let lastMouseMoveEvent = null;
    const debounceMouseMove = (e) => {
        lastMouseMoveEvent = e;
        if (!circleCursor._moving) {
            circleCursor._moving = true;
            requestAnimationFrame(() => {
                circleCursor.style.left = `${lastMouseMoveEvent.clientX}px`;
                circleCursor.style.top = `${lastMouseMoveEvent.clientY}px`;
                circleCursor._moving = false;
            });
        }
    };

    document.addEventListener('mousemove', debounceMouseMove);

    // Use event delegation for hover effects
    document.addEventListener('mouseover', (e) => {
        if (e.target.matches(hoverTargets) || e.target.closest(hoverTargets)) {
            circleCursor.style.transform = 'translate(-50%, -50%) scale(2)';
            circleCursor.style.opacity = '0.75';
            circleCursor.style.backgroundColor = 'transparent';
        } else if (e.target === usImage) {
            // Special case for the specific image
            circleCursor.classList.add('heart-cursor');
        }
    });

    document.addEventListener('mouseout', (e) => {
        if (e.target.matches(hoverTargets) || e.target.closest(hoverTargets)) {
            circleCursor.style.transform = 'translate(-50%, -50%) scale(1)';
            circleCursor.style.opacity = '1';
            circleCursor.style.backgroundColor = '#6FE4FC';
        } else if (e.target === usImage) {
            // Remove the heart cursor class when not hovering
            circleCursor.classList.remove('heart-cursor');
        }
    });
};

export default cursor;
