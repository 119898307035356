const adjustScrollOffset = () => {
    const FOOTER_SELECTOR = ".footer-top";
    const OFFSET_ATTRIBUTE = "data-scroll-offset";
    const MOBILE_BREAKPOINT = 768;
    const LARGE_SCREEN_BREAKPOINT = 1920;

    const OFFSET_VALUES = {
        LARGE_SCREEN: "10%",
        DEFAULT: "30%",
        MOBILE: "10%"
    };

    /**
     * Retrieves the current window width.
     * @returns {number} The width of the window.
     */
    const getWindowWidth = () => window.innerWidth;

    /**
     * Sets the scroll offset for a given element based on the window width.
     * @param {Element} element - The DOM element to set the offset for.
     * @param {string} offset - The offset value to set.
     */
    const setScrollOffset = (element, offset) => {
        element.setAttribute(OFFSET_ATTRIBUTE, offset);
    };

    /**
     * Updates the scroll offsets for all relevant elements.
     */
    const updateOffsets = () => {
        const windowWidth = getWindowWidth();
        const footerTop = document.querySelector(FOOTER_SELECTOR);
        const allElements = document.querySelectorAll(`[${OFFSET_ATTRIBUTE}]`);

        if (!footerTop) {
            console.warn(`Element with selector "${FOOTER_SELECTOR}" not found.`);
            return;
        }

        // Determine and set offset for the footer-top element
        const footerOffset = windowWidth > LARGE_SCREEN_BREAKPOINT
            ? OFFSET_VALUES.LARGE_SCREEN
            : OFFSET_VALUES.DEFAULT;
        setScrollOffset(footerTop, footerOffset);

        // Iterate over all elements and set their offsets accordingly
        allElements.forEach(element => {
            if (!element.classList.contains("footer-top")) {
                const offset = windowWidth <= MOBILE_BREAKPOINT
                    ? OFFSET_VALUES.MOBILE
                    : OFFSET_VALUES.DEFAULT;
                setScrollOffset(element, offset);
            }
        });
    };

    // Initialize offsets on function call
    updateOffsets();

    // Update offsets on window resize
    window.addEventListener("resize", updateOffsets);
};

// Export the optimized function
export default adjustScrollOffset;
