import $ from 'jquery';

const modal = () => {
    // Initialize all modals
    const modals = [
        { link: '.link-modal-about', modal: '#modal-about' },
        { link: '.link-modal-contact', modal: '#modal-contact' },
        { link: '.link-modal-network', modal: '#modal-network' },
        { link: '.link-modal-partners', modal: '#modal-partners' },
        { link: '.link-modal-partnership', modal: '#modal-partnerships' },
        { link: '.link-modal-services', modal: '#modal-services' }
    ];

    /**
     * Initializes a single modal with the given link and modal selectors.
     * @param {string} linkSelector - Selector for the element that triggers the modal.
     * @param {string} modalSelector - Selector for the modal element.
     */
    const initializeModal = (linkSelector, modalSelector) => {
        const $modal = $(modalSelector);
        const $modalInner = $modal.find('.modal-inner');
        const $closeBtn = $modal.find('.modal-close');

        // Event handler to open the modal
        const openModal = (e) => {
            e.preventDefault();
            $modal.addClass('show');
            $modalInner.scrollTop(0);
            $('body').addClass('modal-open'); // Prevent background scrolling
        };

        // Event handler to close the modal
        const closeModal = (e) => {
            e.preventDefault();
            $modal.removeClass('show');
            $('body').removeClass('modal-open');
        };

        // Event handler to close modal when clicking outside the modal content
        const outsideClick = (e) => {
            if (!$(e.target).closest('.modal-inner').length) {
                closeModal(e);
            }
        };

        // Bind event listeners
        $(document).on('click', linkSelector, openModal);
        $closeBtn.on('click', closeModal);
        $modal.on('click', outsideClick);

        // Optional: Close modal on pressing the ESC key
        $(document).on('keydown', (e) => {
            if (e.key === 'Escape' && $modal.is(':visible')) {
                closeModal(e);
            }
        });
    };

    // Initialize each modal
    modals.forEach(({ link, modal }) => initializeModal(link, modal));
};

export default modal;
