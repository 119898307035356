import "@babel/polyfill";
import $ from 'jquery';
import loader from './__modules/loader';
import tab from './__modules/tab';
import locomotiveInit from './__modules/locomotive';
import cursor from './__modules/cursor';
import modal from './__modules/modal';
import updateViewport from './__modules/updateViewport';
import scaleShape from './__modules/scaleShape';
import equalContentHeight from './__modules/equalContentHeight';
import service from './__modules/services';
import careers from "./__modules/careers";
import careerSwiper from './__modules/careerSwiper';
import notFound from "./__modules/notFound";
import adjustScrollOffset from "./__modules/adjustScrollOffset";
import header from "./__modules/header";
import { gsap } from 'gsap';

document.addEventListener('DOMContentLoaded', () => {
	cursor();

	if ($('.tab').length) {
		tab();
	}

	if (!localStorage.getItem("loaded") && $('.loader').length) {
		gsap.set(".loader-counter", { className: "+= loader-counter loader-counter--visible" });
		gsap.set(".loader-counter", { className: "+= loader-counter loader-counter--visible loader-counter--hide" }, 0.15);
	}

	modal();

	adjustScrollOffset();
});

window.addEventListener('load', () => {
	if ($('.main-shape-video').length) {
		scaleShape();
	}

	if ($('.loader').length) {
		equalContentHeight();
		service();
		let scroll = locomotiveInit();
		updateViewport();
		loader();
		header(scroll);
	}

	if($(".career").length) {
		careers();
		careerSwiper();
		let scroll = locomotiveInit();
		header(scroll);
	}

	if ($('.notfound').length) {
		notFound();
		locomotiveInit();
	}
});

// Remove unused event listeners unless needed later
window.addEventListener('resize', () => {
	if ($('.loader').length) {
		equalContentHeight();
	}
});
// window.addEventListener('scroll', () => {});
