import { gsap } from 'gsap';

/**
 * Initializes animations for the 404 Not Found page.
 */
const notFound = () => {
    // Cache DOM elements to improve performance
    const notFoundImage = document.querySelector('.notfound-content img');
    const headerLinks = document.querySelectorAll('header a');

    // Ensure elements exist before animating
    if (!notFoundImage) {
        console.warn("Not Found Image element not found!");
        return;
    }

    if (!headerLinks.length) {
        console.warn("Header links not found!");
        return;
    }

    // Create a GSAP timeline for sequential animations
    const tl = gsap.timeline();

    // Animate the Not Found image
    tl.to(notFoundImage, {
        autoAlpha: 0.075,
        duration: 0.5,
        ease: "power1.out"
    });

    // Animate header links with a slight overlap
    tl.to(headerLinks, {
        autoAlpha: 1,
        duration: 0.5,
        ease: "power1.out",
        stagger: 0.1 // Adds a slight delay between each link's animation
    }, "-=0.4"); // Overlap the animation by 0.4 seconds
};

export default notFound;
