import { gsap, Power4 } from 'gsap';
import SplitText from '../__vendor/SplitText.min.js'; // Ensure SplitText is correctly imported

// Centralized selectors for easy management and reusability
const SELECTORS = {
    splitText: ".main h1, .main h2, .main p, .section .title, .services .title, .section .column p, .sitemap-column-text p, .section .column h3, .section .column h4, .services p, .loader-text p",
    loader: ".loader",
    loaderVideo: ".loader video",
    main: ".main",
    headerLinks: "header a",
    mainShapeVideo: ".main-shape-video",
    mainLines: ".main-lines",
    loaderLogo: ".loader-logo",
    loaderText: ".loader-text p",
    loaderCounter: ".loader-counter > div",
    loaderTextProgress: ".loader-text-progress",
    loaderTextProgressDiv: ".loader-text-progress > div",
    counter: "#counter",
    mainHeaders: ".main h1, .main h2",
    mainParagraph: ".main p"
};

/**
 * Caches and returns all necessary DOM elements.
 * @returns {Object} An object containing all cached DOM elements.
 */
const getElements = () => {
    const {
        splitText,
        loader,
        loaderVideo,
        main,
        headerLinks,
        mainShapeVideo,
        mainLines,
        loaderLogo,
        loaderText,
        loaderCounter,
        loaderTextProgress,
        loaderTextProgressDiv,
        counter,
        mainHeaders,
        mainParagraph
    } = SELECTORS;

    return {
        splitTextElements: splitText,
        loader: document.querySelector(loader),
        video: document.querySelector(loaderVideo),
        main: document.querySelector(main),
        headerLinks: document.querySelectorAll(headerLinks),
        mainShapeVideo: document.querySelector(mainShapeVideo),
        mainLines: document.querySelector(mainLines),
        loaderLogo: document.querySelector(loaderLogo),
        loaderText: document.querySelectorAll(loaderText),
        loaderCounter: document.querySelectorAll(loaderCounter),
        loaderTextProgress: document.querySelector(loaderTextProgress),
        loaderTextProgressDiv: document.querySelectorAll(loaderTextProgressDiv),
        counter: document.getElementById('counter'),
        mainHeaders: document.querySelectorAll(mainHeaders),
        mainParagraph: document.querySelectorAll(mainParagraph)
    };
};

/**
 * Initializes the SplitText plugin on specified elements.
 * @param {string} elements - CSS selector string for SplitText.
 */
const initializeSplitText = (elements) => {
    if (typeof SplitText !== 'undefined') {
        new SplitText(elements, { type: "lines" });
    } else {
        console.warn("SplitText plugin is not loaded.");
    }
};

/**
 * Creates a GSAP timeline with default settings for consistency.
 * @returns {GSAPTimeline} A new GSAP timeline instance.
 */
const createTimeline = () => {
    return gsap.timeline({ paused: true, defaults: { ease: Power4.easeInOut } });
};

/**
 * Handles the animation sequence when the loader has already been loaded.
 * @param {GSAPTimeline} tl - The GSAP timeline instance.
 * @param {Object} elements - The cached DOM elements.
 */
const handleLoadedState = (tl, elements) => {
    const {
        video,
        main,
        mainShapeVideo,
        mainLines
    } = elements;

    tl.to(video, { opacity: 1, duration: 0.5, ease: "none" })
        .set(main, { className: "+= main main-visible" })
        .to(SELECTORS.loader, { duration: 1, scale: 0.35, transformOrigin: "center", ease: Power4.easeInOut }, 2.6)
        .set(SELECTORS.loader, { className: "+= loader loader--remove" }, 3.3)
        .to(SELECTORS.headerLinks, { autoAlpha: 1, duration: 0.5 }, 3.5)
        .set(SELECTORS.mainHeaders, { className: "+= visible" }, 3.5)
        .set(SELECTORS.mainHeaders, { className: "+= visible" }, 3.7) // Assuming this is intentional
        .set(SELECTORS.mainParagraph, { className: "+= visible" }, 3.8)
        .to(mainShapeVideo, { duration: 0.35, ease: Power4.ease, clipPath: "circle(116.0% at 50% 0%)" }, 3.35)
        .to(mainLines, {
            autoAlpha: 1,
            ease: Power4.ease,
            duration: 0.5,
            onComplete: () => {
                localStorage.setItem("loaded", true);
            }
        }, 4.2);
};

/**
 * Handles the animation sequence when the loader has not been loaded yet.
 * @param {GSAPTimeline} tl - The GSAP timeline instance.
 * @param {Object} elements - The cached DOM elements.
 */
const handleNotLoadedState = (tl, elements) => {
    const {
        video,
        main,
        loaderLogo,
        loaderText,
        loaderCounter,
        loaderTextProgress,
        loaderTextProgressDiv,
        counter
    } = elements;

    const isMobile = window.innerWidth < 992;

    tl.set(loaderLogo, { className: "+= loader-logo loader-logo--visible" }, 5)
        .set(main, { className: "+= main main-visible" })
        .set(loaderLogo, { className: "+= loader-logo loader-logo--visible loader-logo--hide" }, 5.15)
        .to(loaderCounter, { autoAlpha: 1, duration: 0.3 }, 0.1)
        .to(loaderText, { autoAlpha: 1 }, 0) // Assuming default position
        .to(`${SELECTORS.loaderLogo} img`, { autoAlpha: 1, duration: 0.3 }, 5.1)
        .to(loaderTextProgress, {
            scaleX: 1,
            duration: 0.3,
            xPercent: isMobile ? -50 : 0
        }, 5.1)
        .to(loaderTextProgressDiv, { scaleX: 1, duration: 7.75 }, 5.1)
        .to(SELECTORS.loader, { duration: 1, scale: 0.35, transformOrigin: "center", ease: Power4.easeInOut }, 11)
        .to([SELECTORS.loaderLogo, SELECTORS.loaderText], { autoAlpha: 0, duration: 0.3 }, 11.1)
        .to(SELECTORS.loaderTextProgress, { duration: 0.1, scaleX: 0 }, 11.2 )
        .set(SELECTORS.loader, { className: "+= loader loader--remove" }, 11.7)
        .to(SELECTORS.headerLinks, { autoAlpha: 1, duration: 0.5 }, 11.9)
        .set(SELECTORS.mainHeaders, { className: "+= visible" }, 11.9)
        .set(SELECTORS.mainHeaders, { className: "+= visible" }, 12.1) // Assuming this is intentional
        .set(SELECTORS.mainParagraph, { className: "+= visible" }, 12.2)
        .to(elements.mainShapeVideo, { duration: 0.35, ease: Power4.ease, clipPath: "circle(116.0% at 50% 0%)" }, 11.75)
        .to(elements.mainLines, {
            autoAlpha: 1,
            ease: Power4.ease,
            duration: 0.5,
            onComplete: () => {
                localStorage.setItem("loaded", true);
            }
        }, 12.6)
        .fromTo(counter,
            { innerHTML: 0 },
            {
                duration: 4,
                innerHTML: 100,
                roundProps: "innerHTML",
                ease: "none",
                onUpdate: function () {
                    const value = Math.round(this.targets()[0].innerHTML);
                    counter.innerHTML = value;
                    const opacityValue = value / 100;

                    gsap.to(elements.video, { opacity: opacityValue, duration: 0.1, ease: "none" });

                    if (value === 50) {
                        elements.video.play();
                    }

                    if (value === 100) {
                        gsap.to(".loader-counter", { autoAlpha: 0, duration: 0.5, scale: 0.95 });
                        gsap.set(".loader-text p", { className: "+= visible" });
                    }
                }
            },
            0.65
        );
};

/**
 * Initializes the loader animation based on the loaded state.
 */
const loader = () => {
    const elements = getElements();
    initializeSplitText(elements.splitTextElements);

    const loaderTimeline = createTimeline();
    const isLoaded = localStorage.getItem("loaded");

    if (isLoaded) { // Corrected condition to match typical loaded state
        elements.video.play();
        handleLoadedState(loaderTimeline, elements);
    } else {
        handleNotLoadedState(loaderTimeline, elements);
    }

    loaderTimeline.play();
};

export default loader;
