import Swiper from 'swiper';
import { Navigation, EffectCoverflow } from 'swiper/modules';

const careerSwiper = () => {
    // Initialize Swiper with desired configuration
    const careerSwiper = new Swiper('.career-swiper', {
        modules: [Navigation, EffectCoverflow],
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        grabCursor: true,
        centeredSlides: true,
        speed: 600,
        autoplay: {
            delay: 3000,
        },
        loop: true,
        effect: 'coverflow',
        coverflowEffect: {
            rotate: 0,
            stretch: 20,
            depth: 300,
            modifier: 1,
            slideShadows: false,
        },
        breakpoints: {
            0: { 
                slidesPerView: 1.25, 
            },
            992: { 
                slidesPerView: 'auto', 
            },
        },
    });

    // Cache jQuery selector for optimization
    const $careerSwiperSlides = $('.career-swiper .swiper-slide');

    // Function to update slide classes
    const updateSlideClasses = () => {
        $careerSwiperSlides.removeClass('swiper-slide-prev-before');
        const $prevSlide = $('.career-swiper .swiper-slide-active').prev().prev();
        if ($prevSlide.length) {
            $prevSlide.addClass('swiper-slide-prev-before');
        }
    };

    // Initial call to set the custom class on the correct slide
    updateSlideClasses();

    // Event handler to update slide classes on transition
    careerSwiper.on('transitionStart', updateSlideClasses);
};

// Export the optimized function
export default careerSwiper;
