import { gsap } from 'gsap';
import SplitText from '../__vendor/SplitText.min.js'; // Ensure SplitText is correctly imported

const careers = () => {
    // Define all relevant selectors as constants for easy management
    const SELECTORS = {
        splitText: ".career .title, .career p, .open-positions .title",
        careerTextTitle: ".career-text .title",
        careerTextParagraph: ".career-text p",
        headerLinks: "header a"
    };

    /**
     * Initializes the SplitText plugin on the specified elements.
     * @param {string} selector - The CSS selector for elements to split.
     */
    const initializeSplitText = (selector) => {
        new SplitText(selector, { type: "lines" });
    };

    /**
     * Creates and configures the GSAP timeline for the careers section.
     * @returns {GSAPTimeline} The configured GSAP timeline.
     */
    const createTimeline = () => {
        // Create a timeline with default settings for better performance
        return gsap.timeline({ defaults: { ease: "none" } });
    };

    /**
     * Configures the animation sequence for the careers section.
     * @param {GSAPTimeline} timeline - The GSAP timeline to configure.
     */
    const configureTimeline = (timeline) => {
        timeline
            // Step 1: Add 'title show' classes to career-text titles immediately
            .set(SELECTORS.careerTextTitle, {
                className: "+= title show"
            })

            // Step 2: After a short delay, add 'title show visible' to titles and paragraphs
            .set([SELECTORS.careerTextTitle, SELECTORS.careerTextParagraph], {
                className: "+= title show visible"
            }, "+=0.2") // Using relative positioning for better readability

            // Step 3: Add 'show' class to career-text paragraphs immediately after previous step
            .set(SELECTORS.careerTextParagraph, {
                className: "+= show"
            })

            // Step 4: After a short delay, add 'show visible' to career-text paragraphs
            .set(SELECTORS.careerTextParagraph, {
                className: "+= show visible"
            }, "+=0.2")

            // Step 5: Fade in header links with a slight delay
            .to(SELECTORS.headerLinks, {
                autoAlpha: 1,
                duration: 0.5
            }, "<0.1"); // Using relative positioning with "<" to indicate overlap
    };

    /**
     * Initializes the careers section animations.
     */
    const initCareersAnimation = () => {
        // Initialize SplitText on the specified elements
        initializeSplitText(SELECTORS.splitText);

        // Create and configure the GSAP timeline
        const timeline = createTimeline();
        configureTimeline(timeline);

        // Play the timeline
        timeline.play();
    };

    // Execute the animation initialization
    initCareersAnimation();
};

// Export the optimized function
export default careers;
