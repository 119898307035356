import $ from "jquery";
import { gsap, Power4 } from 'gsap';
import Swiper from "swiper";
import { Navigation, EffectFade, EffectCreative } from 'swiper/modules';
import { MorphSVGPlugin } from "../__vendor/MorphSVGPlugin.min.js";
gsap.registerPlugin(MorphSVGPlugin);

// Function to debounce events
const debounce = (func, wait) => {
	let timeout;
	return (...args) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => func.apply(this, args), wait);
	};
};

const service = () => {
	// Common Swiper settings
	const swiperDefaults = {
		modules: [Navigation, EffectFade, EffectCreative],
		//slidesPerView: 1,
		speed: 700,
		effect: 'fade',
		fadeEffect: { crossFade: true },
		loop: true
	};

	// Initialize Swipers
	const initializeSwiper = (selector, customSettings) => new Swiper(selector, { ...swiperDefaults, ...customSettings });
	let initialLoad = true;

	const servicesSwiper = initializeSwiper(".service-text > .swiper", {
		speed: 550,
		effect: 'creative',
		navigation: {
			nextEl: ".service-arrows .swiper-button-next",
			prevEl: ".service-arrows .swiper-button-prev"
		},
		creativeEffect: {
			prev: {
				translate: ['-10%', 0, 0],
				scale: 0.975,
				opacity: 0,
				origin: 'left center'
			},
			next: {
				translate: ['10%', 0, 0],
				scale: 0.975,
				opacity: 0,
				origin: 'left center'
			},
			active: {
				translate: [0, 0, 0],
				scale: 1,
				opacity: 1,
				origin: 'left center'
			}
		},
		on: {
			init: function () {
				if ($(window).width() > 991) {
					let shapeService = $(".services-shape"),
						serviceText = $(".service-text"),
						shapeServiceHeight = shapeService.outerHeight(),
						serviceTextHeight = serviceText.outerHeight();

					if (shapeServiceHeight > serviceTextHeight) {
						console.log(shapeServiceHeight - serviceTextHeight);
						serviceText.css("margin-top", ((shapeServiceHeight - serviceTextHeight) / 2) + "px");
					}
				}
			}
		}
	});

	const paths = [
		"#service-icon-1",
		"#service-icon-2",
		"#service-icon-3",
		"#service-icon-4",
		"#service-icon-5"
	];

	const pathData = [
		"M48,485.4v-67.2h156c8.7,0,15.5-6.7,15.5-15.5V97.3c0-8.7-6.7-15.5-15.5-15.5H48V14.6h156c8.7,0,15.5,6.7,15.5,15.5v36.3 c0,8.7,6.7,15.5,15.5,15.5h37.7c8.7,0,15.5,6.7,15.5,15.5v305.3c0,8.7,6.7,15.5,15.5,15.5h156v67.2h-156c-8.7,0-15.5-6.7-15.5-15.5 v-36.3c0-8.7-7.4-15.5-16.1-15.5h-37c-8.7,0-15.5,6.7-15.5,15.5v36.3c0,8.7-6.7,15.5-15.5,15.5H48z",
		"M21.6,97.7c0-8.7,6.7-15.5,15.5-15.5h37.6c8.7,0,15.5-6.7,15.5-15.5V30.5c0-8.7,6.7-15.5,15.5-15.5h242.6 c8.7,0,16.1,6.7,16.1,15.5v36.3c0,8.7,6.7,15.5,15.5,15.5h37.6c8.7,0,15.5,6.7,15.5,15.5v103.5c0,8.7-6.7,15.5-15.5,15.5h-37.6 c-8.7,0-15.5,6.7-15.5,15.5v36.3c0,8.7-7.4,15.5-16.1,15.5H105.5c-8.7,0-15.5,6.7-15.5,15.5v103.5c0,8.7,6.7,15.5,15.5,15.5h327.2 v67.2H105.5c-8.7,0-15.5-6.7-15.5-15.5v-36.3c0-8.7-6.7-15.5-15.5-15.5H37c-8.7,0-15.5-6.7-15.5-15.5V299.2 c0-8.7,6.7-15.5,15.5-15.5h37.6c8.7,0,15.5-6.7,15.5-15.5V232c0-8.7,6.7-15.5,15.5-15.5h242.6c8.7,0,16.1-6.7,16.1-15.5V97.7 c0-8.7-7.4-15.5-16.1-15.5H105.5c-8.7,0-15.5,6.7-15.5,15.5v51.7H21.6V97.7L21.6,97.7z",
		"M21.6,97.7c0-8.7,6.7-15.5,15.5-15.5h37.6c8.7,0,15.5-6.7,15.5-15.5V30.5c0-8.7,7.4-15.5,15.5-15.5h243.2 c8.7,0,15.5,6.7,15.5,15.5v36.3c0,8.7,6.7,15.5,15.5,15.5h37.6c8.7,0,15.5,6.7,15.5,15.5v103.5c0,8.7-6.7,15.5-15.5,15.5h-37.6 c-8.7,0-15.5,6.7-15.5,15.5v36.3c0,8.7,6.7,15.5,15.5,15.5h37.6c8.7,0,15.5,6.7,15.5,15.5v103.5c0,8.7-6.7,15.5-15.5,15.5h-37.6 c-8.7,0-15.5,6.7-15.5,15.5v36.3c0,8.7-6.7,15.5-15.5,15.5H105.5c-8.1,0-15.5-6.7-15.5-15.5v-36.3c0-8.7-6.7-15.5-15.5-15.5H37 c-8.7,0-15.5-6.7-15.5-15.5V351h68.5v51.7c0,8.7,7.4,15.5,15.5,15.5h243.2c8.7,0,15.5-6.7,15.5-15.5V299.2c0-8.7-6.7-15.5-15.5-15.5 H158.6v-67.2h190.2c8.7,0,15.5-6.7,15.5-15.5V97.7c0-8.7-6.7-15.5-15.5-15.5H105.5c-8.1,0-15.5,6.7-15.5,15.5v51.7H21.6V97.7 L21.6,97.7z",
		"M21.4,200.9V14.6H90v186.3c0,8.7,6.7,15.5,15.5,15.5h243.4c8.7,0,15.5-6.7,15.5-15.5V14.6H433v186.3 c0,8.7-6.7,15.5-15.5,15.5h-37.7c-8.7,0-15.5,6.7-15.5,15.5v36.3c0,8.7,6.7,15.5,15.5,15.5h37.7c8.7,0,15.5,6.7,15.5,15.5v186.3 h-68.6V299.1c0-8.7-6.7-15.5-15.5-15.5H105.4c-8.7,0-15.5-6.7-15.5-15.5v-36.3c0-8.7-6.7-15.5-15.5-15.5H36.9 C28.1,216.4,21.4,209.7,21.4,200.9z",
		"M34.41,351.37H103v51.79a15.22,15.22,0,0,0,15.47,15.46H361.24c8.74,0,16.14-6.72,16.14-15.46V299.59c0-8.74-7.4-15.47-16.14-15.47H119.14c-8.74,0-16.14-6.72-16.14-15.46V232.34a15.21,15.21,0,0,0-15.46-15.46H49.88a15.22,15.22,0,0,1-15.47-15.47V97.84A15.21,15.21,0,0,1,49.88,82.38H87.54A15.22,15.22,0,0,0,103,66.91V30.59c0-8.74,7.4-15.46,16.14-15.46H446V82.38H119.14C110.4,82.38,103,89.1,103,97.84V201.41c0,8.74,7.4,15.47,16.14,15.47h242.1c8.74,0,16.14,6.72,16.14,15.46v36.32a15.21,15.21,0,0,0,15.47,15.46h37.66A15.22,15.22,0,0,1,446,299.59V403.16a15.21,15.21,0,0,1-15.46,15.46H392.85a15.22,15.22,0,0,0-15.47,15.47v36.32c0,8.74-7.4,15.46-16.14,15.46H118.47A15.22,15.22,0,0,1,103,470.41V434.09a15.22,15.22,0,0,0-15.46-15.47H49.88a15.21,15.21,0,0,1-15.47-15.46V351.37Z",
		"M35.7,300.6c0-4.5,1.6-8.4,4.8-11.4c3.2-3.1,7-4.6,11.6-4.6h38.9c4.5,0,8.4-1.6,11.4-4.8c3.1-3.2,4.6-7,4.6-11.6v-37.5c0-4.5-1.5-8.4-4.6-11.4c-3.1-3.1-6.9-4.6-11.4-4.6H52.1c-4.5,0-8.4-1.5-11.6-4.6c-3.2-3.1-4.8-6.9-4.8-11.4V90.9c0-4.5,1.6-8.4,4.8-11.4c3.2-3.1,7-4.6,11.6-4.6h38.9c4.5,0,8.4-1.5,11.4-4.6s4.6-6.9,4.6-11.4V21.4c0-4.5,1.6-8.4,4.9-11.6S119.1,5,123.7,5H376c4.5,0,8.4,1.6,11.4,4.8s4.6,7,4.6,11.6v37.5c0,4.5,1.5,8.4,4.6,11.4c3.1,3.1,6.9,4.6,11.4,4.6h39.2c4.5,0,8.4,1.5,11.4,4.6c3.1,3.1,4.6,6.9,4.6,11.4v53.9H392V90.9c0-4.5-1.5-8.4-4.6-11.4c-3.1-3.1-6.9-4.6-11.4-4.6H123.7c-4.5,0-8.5,1.5-11.8,4.6c-3.3,3.1-4.9,6.9-4.9,11.4v107.8c0,4.5,1.6,8.4,4.9,11.4c3.3,3.1,7.2,4.6,11.8,4.6H376c4.5,0,8.4,1.5,11.4,4.6c3.1,3.1,4.6,6.9,4.6,11.4v37.5c0,4.5,1.5,8.4,4.6,11.6c3.1,3.2,6.9,4.8,11.4,4.8h39.2c4.5,0,8.4,1.5,11.4,4.6c3.1,3.1,4.6,6.9,4.6,11.4v107.4c0,4.5-1.5,8.4-4.6,11.4c-3.1,3.1-6.9,4.6-11.4,4.6h-39.2c-4.5,0-8.4,1.5-11.4,4.6c-3.1,3.1-4.6,6.9-4.6,11.4V478c0,4.5-1.6,8.4-4.9,11.4c-3.3,3.1-7.2,4.6-11.8,4.6H123.7c-4.5,0-8.5-1.5-11.8-4.6s-4.9-6.9-4.9-11.4v-37.9c0-4.5-1.5-8.4-4.6-11.4s-6.9-4.6-11.4-4.6H52.1c-4.5,0-8.4-1.5-11.6-4.6s-4.8-6.9-4.8-11.4V300.6z M107,408.1c0,4.5,1.6,8.4,4.9,11.4s7.2,4.6,11.8,4.6h251.7c4.5,0,8.5-1.5,11.8-4.6c3.3-3.1,4.9-6.9,4.9-11.4V300.6c0-4.5-1.6-8.4-4.9-11.4c-3.3-3.1-7.2-4.6-11.8-4.6H123.7c-4.5,0-8.5,1.5-11.8,4.6c-3.3,3.1-4.9,6.9-4.9,11.4V408.1z"
	];

	const svgPath = document.getElementById('service-icon');

	servicesSwiper.on("slideChange", () => {
		if (initialLoad) {
			initialLoad = false; // Set the flag to false after the initial load
			return; // Exit early to avoid calling the function on initial load
		}

		const currentIndex = servicesSwiper.realIndex; // Get current slide index
		const nextD = pathData[currentIndex]; // Get the 'd' attribute of the next path

		// Morph the SVG path
		gsap.to(svgPath, {
			duration: 0.5,
			morphSVG: {
				shape: nextD,
				shapeIndex: 0, // Adjust if needed for better morphing
			},
			ease: 'power4.inOut'
		});
	});

	const modalSwiper = initializeSwiper('.modal-services-slider', {
		navigation: {
			nextEl: '.modal-services .swiper-button-next',
			prevEl: '.modal-services .swiper-button-prev'
		},
		autoHeight: true,
		breakpoints: {
			992: {
				autoHeight: false,
			}
		},
	});

	modalSwiper.on('slideChangeTransitionStart', () => {
		const newIndex = modalSwiper.realIndex;
		servicesSwiper.slideToLoop(newIndex);
	});

	servicesSwiper.on('slideChangeTransitionStart', () => {
		const newIndex = servicesSwiper.realIndex;
		modalSwiper.slideToLoop(newIndex);
	});

	// Update modalSwiper on modal open
	$('.link-modal-services').on('click', () => modalSwiper.update());

	// Cache jQuery selectors
	const $window = $(window);
	const $servicesShape = $('.services-shape');
	const $services = $('.services');

	// Update height of .services based on .services-shape height
	const updateServicesHeight = () => {
		if ($window.width() > 991) {
			const shapeHeight = $servicesShape.outerHeight();
			$services.height(shapeHeight);
		} else {
			$services.css('height', 'auto'); // Reset height if below threshold
		}
	};

	// Call the function initially
	updateServicesHeight();

	// Optimize resize handling with debounce
	$window.on('resize', debounce(updateServicesHeight, 300));
};

export default service;