const updateViewport = () => {
    const root = document.documentElement;
    let isUpdating = false;

    const updateVh = () => {
        if (!isUpdating) {
            isUpdating = true;
            requestAnimationFrame(() => {
                const vh = window.innerHeight * 0.01;
                root.style.setProperty('--vh', `${vh}px`);
                isUpdating = false;
            });
        }
    };

    // Initial update
    updateVh();

    // Add event listeners
    window.addEventListener('resize', updateVh);
    window.addEventListener('orientationchange', updateVh);
};

// Ensure the script runs when the DOM is ready
document.addEventListener('DOMContentLoaded', updateViewport);

export default updateViewport;
