import $ from 'jquery';

const tab = () => {
    let isAnimating = false;

    $(".tab-title").on("click", function (e) {
        e.preventDefault();

        if (isAnimating) return;

        const $this = $(this);
        const $activeTab = $(".tab-title.tab-active");
        const $contentToShow = $this.next();
        const $contentToHide = $activeTab.next();

        if (!$this.hasClass("tab-active")) {
            isAnimating = true;

            // Hide the currently active tab content
            if ($activeTab.length > 0) {
                $activeTab.removeClass("tab-active");
                $contentToHide.slideUp(400, () => {
                    // Show the new tab content
                    $this.addClass("tab-active");
                    $contentToShow.slideDown(400, () => {
                        isAnimating = false;
                    });
                });
            } else {
                // No active tab, just show the new tab content
                $this.addClass("tab-active");
                $contentToShow.slideDown(400, () => {
                    isAnimating = false;
                });
            }
        } else {
            // If the clicked tab is already active, hide its content
            isAnimating = true;
            $activeTab.removeClass("tab-active");
            $contentToHide.slideUp(400, () => {
                isAnimating = false;
            });
        }
    });
};

export default tab;
